import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withOktaAuth } from '@okta/okta-react';
import { Route } from 'react-router-dom';
import * as translationUtils from '../../common-utils/translation_utils';
import * as apis from '../../common-utils/api_urls';
import Header from '../../containers/header';
import Footer from '../../containers/footer';
import setHeaderFooterdata from '../../services/getHeaderFooterData';
import HeaderCategories from '../../services/getHeaderCategories';
import setApplicationTilesData from '../../services/getApplicationTilesData';
import getSupportAssetsL1Data from '../../services/getSupportAssetsL1Data';
// import varification from '../../services/varification';

import getAuth from '../../services/getAuth';
import { $, jQuery } from 'jquery';
import ApplicationTiles from '../../containers/applicationTiles/applicationTiles.js';
import SupportAssetsL1Tiles from '../../containers/support/SupportAssetsL1Tiles';
import SupportSearchResult from '../../containers/searchResultPage/SupportSearchResult';
import supportAssetsSearch from '../../services/supportAssetsSearch';
import Profile from '../../containers/header/Profile';
import './main-wrapper.css';
import getHeaderSelect from '../../services/headerSelect';
import HomeBannerComponent from '../../containers/homeComponents/HomeBannerComponent';
import manageuser from '../../containers/delegatedAdmin/manageUser';
import Edituser from '../../containers/delegatedAdmin/editUser';
import AuthUser from '../../containers/delegatedAdmin/AuthUser';
//import edituser from '../../containers/delegatedAdmin/editUser';
import Adduser from '../../containers/delegatedAdmin/Adduser';
import AddLocations from '../../containers/delegatedAdmin/AddLocations';
import { getWCSTrustedToken } from '../../services/getDeletegatedAdminData';
import {
  HeaderFooterJsonTemplates,
  getUserDefaultLanguage,
} from '../../services/getUserDetail';
import axios from 'axios';
import UserTokens from '../../containers/delegatedAdmin/token/UserTokens';
import { Cookies } from 'react-cookie';
import { getEnvDetail, getAPIAuthKey, getNewDomain } from '../../apiconfig';
import MyParkerSearch from '../../containers/search/MyParkerSearch';
import NewSupportPage from '../../containers/support/supportL1Categories.jsx';
import MyParkerHelp from '../../containers/help/myParkerHelp.jsx';


const cookies = new Cookies();

/**
 * MainWrapper Class
 * This class is used as a main parent class for other sub-section classes.
 *
 */

export default withOktaAuth(
  class MainWrapper extends Component {
    _isMounted = false;
    onRefresh = '';
    historyDta = {};
    minHeight = '50%';
    constructor(props) {
      super(props);
      this.state = {
        filters: 'nein',
        newFilterFlag: false,
        authStatus: true,
        intialOrderType: 'all',
        langCode: 'EN',
        result: null,
        pageNumber: 0,
        pageLength: window.initialPageLength,
        orderType: '',
        initialShipType: 'yesterday',
        SelectedCustName: '',
        headerData: null,
        footerData: null,
        isVarified: false,
        initialCustomerSearch: false,
        sellerCode: null,
        categoriesData: null,
        contractId: null,
        categoryCalled: false,
        headerCalled: false,
        totalRecords: 0,
        authId: null,
        authIdCalled: false,
        cookieContractId: '',
        applicationTilesData: null,
        applicationCalled: false,
        supportAssetsL1Data: null,
        supportAssetsL1Called: false,
        userInfo: null,
        headerSelectData: null,
        headerSelectCalled: false,
        applicationTagged: true,
        editUserDetails: null,
        locationList: null,
        isLoading: true,
        selectedLocations: [],
        headerJsonTemplate: null,
        footerJsonTemplate: null,
        userSearchText: '',
        data: {},
      };
      this.defaultAppTile = this.defaultAppTile.bind(this);
      this.userProperties = this.userProperties.bind(this);
      this.locationList = this.locationList.bind(this);
    }

    // https://testmy.parker.com/api/user-ip-address | https://api.ipify.org?format=json
    // async callApiToFetchIP() {
    //   try {
    //     const response = await axios.get('http://localhost:3000/api/sample', {
    //       headers: {
    //         Accept: 'application/json',
    //         'Content-Type': 'application/json',
    //         'ocp-apim-subscription-key': getAPIAuthKey(),
    //       },
    //     });
    //     window.sessionStorage.setItem(
    //       'ClientIPAddress',
    //       response.data.ip_address
    //     );
    //     //return response.data.ip_address;
    //   } catch (error) {
    //     console.error('Error:', error);
    //   }
    // }
    callApiToFetchIP() {
      fetch(getNewDomain() + 'api/user-ip-address')
        .then((response) => response.json())
        .then((data) => {
          window.sessionStorage.setItem('ClientIPAddress', data.ip_address);
        });
      // fetch('http://localhost:3000/api/sample')
      //   .then(function (response) {
      //     return response.json();
      //   })
      //   .then(function (response) {
      //     console.log('ClientIPAddress', response.data.ip_address);
      //     window.sessionStorage.setItem(
      //       'ClientIPAddress',
      //       response.data.ip_address
      //     );

      //   });
    }

    //Made the changes and Moved Varification Code insude wrapper to handle the Logout feature
    async verification() {
      this.callApiToFetchIP();
      if (window.location.hostname.toLowerCase().indexOf('localhost') === -1) {
        try {
          const res = await axios(apis.getVarificationUrl(), {
            method: 'GET',
            withCredentials: true,
          });
          if (res.status == 200) {
            console.log('IDPID==', res.data.idp);

            window.sessionStorage.setItem('oktaSessionMeId', res.data.id);
            window.sessionStorage.setItem('oktaLogin', res.data.login);
            window.sessionStorage.setItem('oktaUserEmail', res.data.login);
            window.sessionStorage.setItem('oktaUserMeId', res.data.userId);
            window.sessionStorage.setItem('oktaUserIDP', res.data.idp['id']);
          }
          // Don't forget to return something

          return res.data;
        } catch (error) {
          console.error(error);
          this.oktalogout();
        }
      } else {
        window.sessionStorage.setItem(
          'oktaSessionMeId',
          '102Eh3DoLj-SPiHN0MDqTarrQ'





        );
      }
    }
    //Ended the Cde

    async checkUser() {
      let userDefaultLang = sessionStorage.getItem("defaultLang");
      let firstLogin = sessionStorage.getItem("firstLogin");
      
      if (this.props.authState.isAuthenticated && !this.state.userInfo) {
        const userInfo = await this.props.authService.getUser();
        let token = localStorage.getItem('okta-token-storage');
        let accessToken = JSON.parse(token).accessToken.value;
       
    
        // const accessToken = this.props.authState.accessToken;
        console.log("accessToken", accessToken, userInfo.email)
        if (
          window.sessionStorage.getItem('WCTrustedToken') == null ||
          window.sessionStorage.getItem('WCTrustedToken') == '' ||
          cookies.get(getEnvDetail() + '_WCTrustedToken') == undefined ||
          cookies.get(getEnvDetail() + '_WCTrustedToken') == null
        ) {
          await getWCSTrustedToken(
            this,
            apis.getWCSTrustedTokenURL(),
            userInfo.email,
            accessToken
          );
        }
        //Commented to Stop the Default Langage flow operated by Megha and tested again
        
        let defaultLang = "EN";
        const urlObj = new URL(window.location.href);
          const pathSegments = urlObj?.pathname?.split('/');
        if(window.location.href.includes('/search')){
          
          if(window.location.href.includes('&vgnextfmt')){
            
            if(pathSegments?.length > 0){
              defaultLang = pathSegments[1];

            }

          } else{
            
            firstLogin = window.sessionStorage.getItem("firstLogin");
           
            if(firstLogin == null){
              defaultLang = await getUserDefaultLanguage();
            }  else{
              if(pathSegments?.length > 0){
                defaultLang = pathSegments[1];
  
              }
            }
        } 

        } else {

            firstLogin = window.sessionStorage.getItem("firstLogin");
            console.log("firstlogin",firstLogin)
            if(firstLogin == null){
             
              defaultLang = await getUserDefaultLanguage();
              
            } else{
              if(pathSegments?.length > 0){
                defaultLang = pathSegments[1];
  
              }
            }
        }
        // defaultLang = await getUserDefaultLanguage();
        //if(firstLogin !== null)
          window.sessionStorage.setItem('defaultLang', defaultLang);
        // } else {
        //   window.sessionStorage.setItem('defaultLang', defaultLang);
        // }
        this.setState({ langCode: defaultLang });
        let newURL =
          window.location.pathname.replace(
            window.location.pathname.substr('/', 3),
            '/' + defaultLang
          ) + window.location.search;
        this.props.history.replace(newURL);
        //Commented to Stop the Default Langage flow operated by Megha and tested again

        window.sessionStorage.setItem('oktaSessionId', accessToken);

        this.verification().then((resp) => {
          if (
            this.state.userInfo != null &&
            this.state.headerCalled == false &&
            window.sessionStorage.getItem('oktaSessionMeId') != null &&
            window.sessionStorage.getItem('defaultLang') != null //Megha
          ) {
            this.setHeaderFooterdata();
            this.state.headerCalled = true;
            translationUtils.setTranslations(this, this.state.langCode);
          } else {
            console.log('logout called');
            ///this.oktalogout();
          }
        });
        //console.log("accessToken",accessToken)
        if (this._isMounted) {
          this.setState({ userInfo });
        }
      }
    }
    oktalogout = async () => {
      sessionStorage.clear();
      console.log('Myauth===', this.props.authService);
      this.props.authService.logout('/logout');
      this.props.authService._oktaAuth.tokenManager.clear();
      this.state.userInfo = 'null';
    };

    setSelectedLocations = (locations) => {
      this.setState({ selectedLocations: locations });
      console.log('locations', locations);
    };
    defaultAppTile() {
      this.setState({
        applicationCalled: false,
        applicationTilesData: null,
      });
    }
    getLoader = (flag) => {
      // console.log(flag)
      const storedScrollPosition = window.scrollY;
      window.scrollTo(0, storedScrollPosition);
      this.setState({ isLoading: flag });
    };

    async componentDidMount() {
      this._isMounted = true;
      // this.checkUser();
    }

    async componentDidUpdate() {
      this._isMounted = true;
      this.checkUser();
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    componentDidMount() {
      const {
        match: { params },
      } = this.props;
      translationUtils.setTranslations(this, params.langCode);
      //translationUtils.setDivision();
      if (params.type !== undefined) {
        this.setState({
          intialOrderType: params.type,
        });
      }
    }

    componentWillMount() {
      window.jQuery('#root').css('min-height', window.innerHeight);
      const {
        match: { params },
      } = this.props;
      translationUtils.setTranslations(this, params.langCode);

      if (params.type !== undefined) {
        this.setState({
          langCode: params.langCode,
        });
        if (params.type !== undefined) {
          this.onRefresh = params.type;
        }
      }
    }

    setVarification(data) {
      this.setState({
        isVarified: data,
      });
      if (sessionStorage.getItem('expireTime') !== undefined) {
        let Diffrece =
          new Date(sessionStorage.getItem('expireTime')) - new Date() - 300000;
        window.setTimeout(function () {
          window.checkExpireTime();
        }, Diffrece);
      }
    }

    setHeaderFooterdata() {
      this.getLoader(true);
      this.checkUser();
      let oktasessmeId = window.sessionStorage.getItem('oktaSessionMeId');
      let curr_cid = window.location.search.includes('cid')
        ? window.location.search.split('cid=')[1]
        : null;
      let cont_contraId = '';
      if (oktasessmeId) {
        HeaderFooterJsonTemplates(
          this,
          oktasessmeId,
          this.state.langCode,
          curr_cid
        )
          .then((res) => {
            if (res != null && res != undefined) {
              this.setState({ data: { header: { orgList: res.orgList } } });
              console.log('hisorytu', this.props.history);
              if (curr_cid === null) {
                this.state.data.header.orgList.map((orgItem) => {
                  if (orgItem.isDefault) {
                    cont_contraId = orgItem.cid;
                  }
                });
                this.props.history.push({
                  pathname: this.props.history.location.pathname,
                  search: '?cid=' + cont_contraId,
                });
              }
              this.setState({
                headerData: this.state.data.header,
                langCode: this.props.history.location.pathname.split('/')[1],
                contractId: curr_cid ? curr_cid : cont_contraId,
              });
              //Code for Redirecting the Non Admin User to MyParkrr
              let had_Admin = window
                .$(res['header-html'])
                .find('.has-admin-check').length;
              if (had_Admin == 0) {
                let checkRedirection = true;
                if(window.location.pathname.includes("/search")){
                  checkRedirection = false;
                }
                if(window.location.pathname.includes("/support")){
                  checkRedirection = false;
                }
                if(window.location.pathname.includes("/help")){
                  checkRedirection = false;
                }
                if(checkRedirection){

                  window.location.href = window
                  .$(res['header-html'])
                  .find('.navbar-brand')
                  .attr('href');
                 } 
               
              }

              window.$('header#header').append(res['header-html']);
              window.$('footer#footer').append(res['footer-html']);
            }
          })
          .then(() => {
            setTimeout(() => {
              this.getLoader(false);
            }, 5000);
            //window.$(window.$('li.user .dropdown-menu .dropdown-item')[1]);
            // .click((event) => {
            //   event.preventDefault();
            //   this.oktalogout();
            // });
          });
      } else {
        this.oktalogout();
      }
    }

    setHeight() {
      let a = window.jQuery('.orderData').height() + 'px';
      window.jQuery('#preloader').css('height', a);
    }

    setHeaderCategoriesData(data) {
      this.setState({
        categoriesData: data,
      });
    }
    setApplicationTilesdata(data) {
      this.setState({
        applicationTilesData: data,
      });
    }
    setHeaderSelectData(data) {
      this.setState({
        headerSelectData: data,
      });
      if (
        this.state.headerSelectData != null &&
        this.state.headerSelectData['8_response'].numFound == 0
      ) {
        this.setState({ applicationTagged: true });
      } else {
        this.setState({ applicationTagged: false });
      }
    }
    setSupportAssetsL1Data(data) {
      this.setState({
        supportAssetsL1Data: data,
      });
    }

    setAuth(data) {
      var auth = '';
      for (var i = 0; i < data[0].AuthId.length; i++) {
        auth = auth + '|' + data[0].AuthId[i];
      }
      auth = auth.substr(1);
      this.setState({
        authId: auth,
      });
    }
    userProperties(data) {
      this.setState({ editUserDetails: data });
    }

    locationList(data) {
      this.setState({ locationList: data });
    }

    setuserSearchText = (value) => {
      this.setState({ userSearchText: value });
    };

    render() {
      const { isVarified } = this.state;
      //console.log(authStatus);
      var errorMsg = '';
      // if (this.state.userInfo != null && this.state.headerCalled==false && window.sessionStorage.getItem('oktaSessionMeId')!=null) {
      //   this.setHeaderFooterdata()
      //   this.state.headerCalled = true
      //   translationUtils.setTranslations(this, this.state.langCode);
      // }

      if (
        window.location.pathname.split('/')[3] === 'all' &&
        this.state.applicationTilesData === null &&
        !this.state.applicationCalled &&
        this.state.userInfo != null
      ) {
        if (this.state.contractId !== null) {
          // console.log('USER INFORMATION ', this.state.userInfo.email);
          let applicationApiUrl = apis.getApplicationTilesUrl(
            this.state.langCode,
            this.state.sellerCode,
            this.state.userInfo.email
          );
          if (applicationApiUrl != undefined) {
            setApplicationTilesData(this, applicationApiUrl);
            this.state.applicationCalled = true;
          }
        }
      }
      if (
        window.location.pathname.split('/')[3] === 'supports-old' &&
        this.state.supportAssetsL1Data === null &&
        !this.state.supportAssetsL1Called
      ) {
        if (this.state.contractId !== null) {
          let supportAssetsL1Url = apis.getSupportAssetsL1DataUrl(
            this.state.langCode,
            this.state.sellerCode
          );
          getSupportAssetsL1Data(this, supportAssetsL1Url);
          this.state.supportAssetsL1Called = true;
          //supportAssetsSearch(this,apis.getSupportAssetResultAPI());
        }
      }
      if (this.state.authId === null && !this.state.authIdCalled) {
        if (this.state.contractId !== null) {
          let headerCategoryURL = apis.getCustAuth(this.state.contractId);
          getAuth(this, headerCategoryURL);
          this.state.authIdCalled = true;
        }
      }

      if (this.state.sellerCode !== null || this.state.contractId != null) {
        window.jQuery.parker = {
          search: {
            localeCode: this.state.langCode,
            searchPage: false,
            customerId: this.state.sellerCode,
            authId: this.state.authId,
            userId: sessionStorage.getItem('oktaSessionId'),
            //customerName: `${this.state.headerData.firstName} ${this.state.headerData.firstName}`,
            custId: this.state.contractId,
            ecomBasePath: 'http://phdev.parker.com',
            translationsAQS: {
              viewMLV: translationUtils.getTranslation(264, 'View'),
              viewCategoryMLV: translationUtils.getTranslation(
                1738,
                'View Category'
              ),
              partNumberMLV: translationUtils.getTranslation(
                1022,
                'Part Number'
              ),
              productCategoryMLV: translationUtils.getTranslation(
                555,
                'Product Category'
              ),
              productSeriesMLV: translationUtils.getTranslation(
                1510,
                'Product Series'
              ),
              partsMLV: translationUtils.getTranslation(1509, 'Parts'),
              partMLV: translationUtils.getTranslation(1565, 'Part'),
              inMLV: translationUtils.getTranslation(1301, 'In'),
              suggestionsMLV: translationUtils.getTranslation(
                1739,
                'Suggestions'
              ),
              productCategoriesMLV: translationUtils.getTranslation(
                1740,
                'Product Categories'
              ),
              viewAllSeriesMLV: translationUtils.getTranslation(
                1749,
                'View All series'
              ),
              viewAllPartsMLV: translationUtils.getTranslation(
                628,
                'View All Parts'
              ),
            },
          },
        };

        return (
          <>
            {this.state.isLoading == true && (
              <div
                id="preloader"
                className="order-status-hifi"
                style={{ height: '100%' }}
              >
                <div className="loading-overlay">&nbsp;</div>
              </div>
            )}
            {/*(this.state.userInfo!==null && this.state.headerData!==null && this.state.categoriesData!==null)?(<Header logout={this.oktalogout} userInfo={this.state.userInfo} orgName={this.state.orgName} isAdmin={this.state.isAdmin} data={this.state.headerData} InitailsellerCode={this.state.sellerCode} contractId={this.state.contractId} categoriesData={this.state.categoriesData} lang={this.state.langCode} callbackFromParent={this.orderCallback}  orderData={this.orderFilterData()}></Header>):(<></>)*/}

            {this.state.userInfo !== null && this.state.headerData !== null ? (
              <Header></Header>
            ) : (
              <></>
            )}

            {/* {(window.location.href.indexOf('myparker/all')>0 && this.state.applicationTilesData!==null && this.state.applicationTilesData!==undefined)?(<ApplicationTiles data={this.state.applicationTilesData} lang={this.state.langCode} cid={this.state.contractId}></ApplicationTiles>):(<></>)} */}
            {/* {this.state.applicationTagged  && <HomeBannerComponent />} */}

            {this.state.applicationTilesData !== null &&
            this.state.applicationTilesData !== undefined ? (
              <Route
                path="/:langCode/myparker/all"
                render={() => (
                  <ApplicationTiles
                    defaultAppTile={this.defaultAppTile}
                    data={this.state.applicationTilesData}
                    lang={this.state.langCode}
                    cid={this.state.contractId}
                  ></ApplicationTiles>
                )}
              />
            ) : (
              <></>
            )}

            {this.state.supportAssetsL1Data !== null &&
            this.state.supportAssetsL1Data !== undefined ? (
              <Route
                path="/:langCode/myparker/support-old"
                render={() => (
                  <SupportAssetsL1Tiles
                    data={this.state.supportAssetsL1Data}
                    lang={this.state.langCode}
                    cid={this.state.contractId}
                    orgName={this.state.orgName}
                    selectedCustName={this.state.SelectedCustName}
                  ></SupportAssetsL1Tiles>
                )}
              />
            ) : (
              <></>
            )}
            {/* <Route path="/:langCode/myparker/supportSearch" component={SupportSearchResult}/> */}
            {}
            <Route path="/:langCode/myparker/search" 
             
             render={() => (
              <MyParkerSearch
                history={this.props.history}
                getLoader={this.getLoader}
                oktaUserInfo={this.state.userInfo}
               
                lang={this.state.langCode}
                cid={this.state.contractId}
                orgList={this.state.headerData.orgList}
              ></MyParkerSearch>
            )}
             />
    
            <Route path="/:langCode/myparker/support" 
             render={() => (              
              <NewSupportPage
                history={this.props.history}
                getLoader={this.getLoader}
                oktaUserInfo={this.state.userInfo}
                editUserDetails={this.state.editUserDetails}
                lang={this.state.langCode}
                cid={this.state.contractId}
                orgList={this.state.headerData.orgList}
                name={'Mani'}
              />
            )}/>

            <Route path="/:langCode/myparker/help" 
             render={() => (              
              <MyParkerHelp
                history={this.props.history}
                getLoader={this.getLoader}
                oktaUserInfo={this.state.userInfo}
                editUserDetails={this.state.editUserDetails}
                lang={this.state.langCode}
                cid={this.state.contractId}
                orgList={this.state.headerData.orgList}
                title={'Help'}
              />
            )}/>

            <Route
              path="/:langCode/myparker/manageUser"
              component={manageuser}
            />
            <Route
              path="/:langCode/myparker/editUser"
              render={() => (
                <Edituser
                  history={this.props.history}
                  getLoader={this.getLoader}
                  oktaUserInfo={this.state.userInfo}
                  editUserDetails={this.state.editUserDetails}
                  lang={this.state.langCode}
                  cid={this.state.contractId}
                  orgList={this.state.headerData.orgList}
                ></Edituser>
              )}
            />
            <Route
              path="/:langCode/myparker/authenticatedUser"
              render={() =>
                this.state.headerData.orgList ? (
                  <AuthUser
                    selectedLocations={this.state.selectedLocations}
                    setSelectedLocations={this.setSelectedLocations}
                    history={this.props.history}
                    lang={this.state.langCode}
                    getLoader={this.getLoader}
                    userProperties={this.userProperties}
                    locationList={this.locationList}
                    headerData={this.state.headerData}
                    cid={this.state.contractId}
                    userInfo={this.state.userInfo}
                    userSearchText={this.state.userSearchText}
                    setuserSearchText={this.setuserSearchText}
                  ></AuthUser>
                ) : (
                  <></>
                )
              }
            />
            <Route
              path="/:langCode/myparker/addUser"
              render={() => (
                <Adduser
                  history={this.props.history}
                  getLoader={this.getLoader}
                  locationList={this.state.headerData.orgList}
                  cid={this.state.contractId}
                  lang={this.state.langCode}
                ></Adduser>
              )}
            />
            <Route
              path="/:langCode/myparker/addLocations"
              render={() => (
                <AddLocations
                  history={this.props.history}
                  getLoader={this.getLoader}
                  orgList={this.state.headerData.orgList}
                  cid={this.state.contractId}
                  lang={this.state.langCode}
                ></AddLocations>
              )}
            />

            <Route
              path="/:langCode/myparker/tokens"
              render={() => (
                <UserTokens
                  history={this.props.history}
                  getLoader={this.getLoader}
                  //tokenList={this.state.headerData.tokenList}
                  cid={this.state.contractId}
                  lang={this.state.langCode}
                ></UserTokens>
              )}
            />

            {/*<Footer data={this.state.footerData} cid={this.state.contractId} lang={this.state.langCode}></Footer> */}
            {this.state.userInfo !== null && this.state.headerData !== null ? (
              <Footer
                getLoader={this.getLoader}
                footerJsonTemplate={this.state.footerJsonTemplate}
              ></Footer>
            ) : (
              <></>
            )}
          </>
        );
      } else {
        return (
          <>
            <div id="preloader" style={{ height: '100%' }}>
              <div className="loading-overlay">&nbsp;</div>
            </div>
          </>
        );
      }
    }
  }
);
